'use strict';
jQuery(document).ready(function ($) {
  // $(".home.section-one #scroll-down").click(function () {
  //   $("html, body").animate(
  //     {
  //       scrollTop: $(".home.section-two").offset().top - 50,
  //     },
  //     1000
  //   );
  // });

  var documentSlides = $('.categories .slide-item');
  var perViewLength = 4;
  var sliderType = 'slider';

  if (documentSlides.length > 2) {
    sliderType = 'carousel';
  } else if (documentSlides.length < 3) {
    $('.categories .glide__arrows').hide();
    perViewLength = documentSlides.length;
  }
  var glide4 = new Glide('.categories', {
    type: sliderType,
    perView: perViewLength,
    gap: 30,
    peek: {
      before: 10,
      after: 100,
    },
    breakpoints: {
      1300: {
        perView: 3,
        peek: {
          before: 10,
          after: 100,
        },
      },
      1300: {
        perView: 2,
        peek: {
          before: 10,
          after: 100,
        },
      },
      1000: {
        perView: 1,
        peek: {
          before: 10,
          after: 100,
        },
      },
      600: {
        perView: 1,
        peek: {
          before: 0,
          after: 0,
        },
        gap: 0,
      },
    },
  });
  if (documentSlides.length > 0) {
    glide4.mount();
  }

  $('#newsletter_form input[name=email]').focus(function () {
    $('#newsletter_form input[name=cpt]').val('425');
  });

  //CONTACT FORM
  $('#newsletter_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      console.log('Submit for form id ' + ev.target.id + ' intercepted');
    })
    .on('forminvalid.zf.abide', function (ev, frm) {
      console.log('Form id ' + ev.target.id + ' is invalid');
    })
    .on('formvalid.zf.abide', function (ev, frm) {
      console.log('form is valid');
      $('#newsletter_form .button1').attr('disabled', 'true');
      $('#newsletter_form .loading').css('display', 'inline-block');
      var that = $('#newsletter_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};
      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });

      if ($('#newsletter_form input[name=cpt]').val() == '425') {
        $.ajax({
          url: url,
          type: method,
          data: data,
          success: function (response) {
            console.log(response);
            console.log('success');
            $('#newsletter_form').hide();
            $('#newsletter_form .button1').removeAttr('disabled');
            $('.newsletter .successMessage').show();
          },
          error: function (response) {
            console.log(response);
            $('#newsletter_form .button1').removeAttr('disabled');
            $('#newsletter_form .loading').hide();
          },
        });
      } else {
        $('#newsletter_form .button1').removeAttr('disabled');
        $('#newsletter_form .loading').hide();
      }
    });
});
